export default {
  English: 'en',
  Farsi: 'ir',
  Hindi: 'in',
  Nepali: 'ne',
  Portuguese: 'pt',
  Punjabi: 'pa',
  Romanian: 'ro',
  Spanish: 'es',
  Urdu: 'ur',
  Russian: 'ru',
  Telugu: 'te',
  Chinese: 'cn',
  Indonesian: 'id',
  French: 'fr',
}