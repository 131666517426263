import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Cookies from 'universal-cookie'

import translationCN from './translations/cn/translation.json'
import translationEN from './translations/en/translation.json'
import translationES from './translations/es/translation.json'
import translationFR from './translations/fr/translation.json'
import translationID from './translations/id/translation.json'
import translationIN from './translations/in/translation.json'
import translationIR from './translations/ir/translation.json'
import translationNE from './translations/ne/translation.json'
import translationPA from './translations/pa/translation.json'
import translationPT from './translations/pt/translation.json'
import translationRO from './translations/ro/translation.json'
import translationRU from './translations/ru/translation.json'
import translationTE from './translations/te/translation.json'
import translationUR from './translations/ur/translation.json'

const cookies = new Cookies()

const init = async () => {
  const currentLocale = cookies.get('currentLanguage') || 'en'

  return i18n.use(initReactI18next).init({
    resources: {
      en: {
        translation: translationEN,
      },
      pt: {
        translation: translationPT,
      },
      ur: {
        translation: translationUR,
      },
      ir: {
        translation: translationIR,
      },
      in: {
        translation: translationIN,
      },
      ne: {
        translation: translationNE,
      },
      pa: {
        translation: translationPA,
      },
      es: {
        translation: translationES,
      },
      ro: {
        translation: translationRO,
      },
      ru: {
        translation: translationRU,
      },
      te: {
        translation: translationTE,
      },
      cn: {
        translation: translationCN,
      },
      id: {
        translation: translationID,
      },
      fr: {
        translation: translationFR,
      }
    },
    lng: currentLocale,
    fallbackLng: 'en',
    supportedLngs: ['en', 'pt', 'ur', 'ir', 'in', 'ne', 'pa', 'es', 'ro', 'ru', 'te', 'cn', 'id', 'fr'],
    // debug: true,
    keySeparator: false,
    react: {
      useSuspense: false,
    },
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
  })
}

export default init
